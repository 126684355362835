import React, { useEffect, useMemo, useRef, useState } from 'react'
import { useHistory } from 'react-router-dom'

import {
  Botao,
  Breadcrumb,
  Cabecalho,
  Filtro,
  InputUnform,
  TabelaRef,
  TabelaResposta,
  Tooltip,
  IconeEditar,
  SelectUnform,
  SelectOpcao,
  Tabela,
  ContainerAcoes,
  TabelaDados,
  FuncoesDataHora
} from 'src/componentes'
import { RotasPrecificacao } from 'src/rotas/academico'
import { Api } from 'src/servicos'
import { FormaPagamentoPorExtenso, TipoOrdenacao } from 'src/tipos'
import { TiposPrecificacao } from 'src/tipos/precificacao'

import { Campo, Container, Dados, Form } from './styles'

interface DadosPesquisa {
  nome: string
  codigo: string
  tipo: string
}

export const PaginaPrecificacao: React.FC = () => {
  const tabelaRef = useRef<TabelaRef>()
  const [dadosPesquisa, setDadosPesquisa] = useState<DadosPesquisa | null>(null)
  const history = useHistory()
  const [paginas, setPaginas] = useState<number | null>(null)

  const tiposPrecificacao = useMemo(() => {
    return [
      { id: TiposPrecificacao.Precificacao, texto: 'Precificacao' },
      { id: TiposPrecificacao.Campanha, texto: 'Campanha' }
    ] as SelectOpcao[]
  }, [])

  useEffect(() => {
    if (dadosPesquisa === null) return
    tabelaRef.current?.IrParaPagina()
    tabelaRef.current?.CarregarDados()
  }, [dadosPesquisa])

  const acaoPesquisa = (dados: DadosPesquisa) => {
    if (dados !== null && paginas !== 1) {
      setPaginas(1)
    }
    setDadosPesquisa(dados)
  }

  const obterDados = async (
    numeroPaginas: number,
    qtdRegistros: number,
    chaveOrdenacao?: string,
    ordem?: TipoOrdenacao
  ): Promise<TabelaResposta | null> => {
    if (paginas !== null) {
      numeroPaginas = paginas
    }

    const dados = await Api.ObterDetalhesPrecificacao(
      dadosPesquisa?.nome,
      dadosPesquisa?.codigo,
      dadosPesquisa?.tipo,
      numeroPaginas,
      qtdRegistros,
      chaveOrdenacao,
      ordem
    )

    if (!dados) return null

    setPaginas(null)

    return {
      Dados: dados.registros,
      Paginacao: {
        Pagina: dados?.opcoesPaginacao?.pagina || 1,
        TotalRegistros: dados?.opcoesPaginacao?.totalRegistros || 1
      }
    } as TabelaResposta
  }

  return (
    <>
      <Cabecalho>
        <Breadcrumb
          titulo="Pesquisa de Precificações"
          atalhos={[
            {
              texto: 'Financeiro'
            },
            {
              texto: 'Precificação'
            }
          ]}
        />
        <Botao
          type="button"
          texto="Incluir"
          tema="Secundario"
          onClick={() => history.push(RotasPrecificacao.Cadastro)}
        />
      </Cabecalho>
      <Filtro>
        <Form acaoSucesso={acaoPesquisa}>
          <Container>
            <Campo>
              <InputUnform
                id="codigo"
                name="codigo"
                label="Código da precificação"
                type="text"
                maxLength={50}
              />
            </Campo>
            <Campo>
              <InputUnform
                id="nome"
                name="nome"
                label="Nome da precificação"
                type="text"
                maxLength={200}
              />
            </Campo>
            <Campo>
              <SelectUnform
                id="tipo"
                name="tipo"
                label="Tipo de precificação"
                opcoes={tiposPrecificacao}
              />
            </Campo>
          </Container>
          <Botao
            type="submit"
            id="btn-aplicar-filtro"
            texto="Aplicar filtro"
            tema="Link"
          />
        </Form>
      </Filtro>
      <Dados>
        <Tabela
          ref={tabelaRef}
          Colunas={[
            {
              Chave: 'nome',
              Texto: 'Nome da Precificação',
              ComOrdenacao: true
            },
            {
              Chave: 'codigo',
              Texto: 'Código',
              ComOrdenacao: true
            },
            {
              Chave: 'tipo',
              Texto: 'Tipo',
              ComOrdenacao: true
            },
            {
              Chave: 'dataInicio',
              Texto: 'Data de Início',
              ComOrdenacao: true,
              Renderizar: (linha: TabelaDados) => (
                <>
                  {linha.dataInicio !== null
                    ? FuncoesDataHora.dataFormatadaComBarras(
                        new Date(linha.dataInicio)
                      )
                    : ''}
                </>
              )
            },
            {
              Chave: 'dataFim',
              Texto: 'Data de Fim',
              ComOrdenacao: true,
              Renderizar: (linha: TabelaDados) => (
                <>
                  {linha.dataFim !== null
                    ? FuncoesDataHora.dataFormatadaComBarras(
                        new Date(linha.dataFim)
                      )
                    : ''}
                </>
              )
            },
            {
              Chave: 'acoes',
              Alinhamento: 'center',
              Renderizar: (linha: TabelaDados) => (
                <ContainerAcoes>
                  <button
                    type="button"
                    data-tip="Editar"
                    data-for={`${linha.id}-tooltip-editar`}
                    onClick={() => {
                      const campanha = linha.tipo === TiposPrecificacao.Campanha
                      history.push({
                        pathname: `${RotasPrecificacao.Edicao}/${linha.id}`,
                        state: { campanha }
                      })
                    }}
                  >
                    {IconeEditar}
                  </button>
                  <Tooltip
                    id={`${linha.id}-tooltip-editar`}
                    place="bottom"
                    offset={{ top: 10 }}
                  />
                </ContainerAcoes>
              )
            }
          ]}
          ObterDados={obterDados}
          ComPaginacao
        />
      </Dados>
    </>
  )
}

export { default as PaginaCadastroPrecificacao } from './cadastro'
