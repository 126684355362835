import { mensagemPadrao } from 'src/dados-estaticos'
import { TiposPrecificacao } from 'src/tipos/precificacao'
import * as Yup from 'yup'

export const schema = Yup.object().shape({
  nome: Yup.string().required(mensagemPadrao),
  codigo: Yup.string().required(mensagemPadrao),
  cpf: Yup.string().required(mensagemPadrao),
  dataInicio: Yup.string().data().required(mensagemPadrao),
  dataFim: Yup.string().data().required(mensagemPadrao),
  valorBase: Yup.string().when('tipoPrecificacao', {
    is: TiposPrecificacao.Campanha,
    then: schema => schema.required(mensagemPadrao),
    otherwise: schema => schema.notRequired()
  }),
  pctDesconto: Yup.string().when('tipoPrecificacao', {
    is: TiposPrecificacao.Campanha,
    then: schema => schema.required(mensagemPadrao),
    otherwise: schema => schema.notRequired()
  }),
  cursoId: Yup.string().required(mensagemPadrao)
})
