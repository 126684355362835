import { MetodosPagamento, Matricula, StatusCarrinhoCompra } from '.'

export enum ModalidadeCurso {
  Livre = 'Livre',
  PosGraduacaoEad = 'PosGraduacaoEad',
  PosGraduacaoEadFlex = 'PosGraduacaoEadFlex',
  PosGraduacaoEadFull = 'PosGraduacaoEadFull',
  PosGraduacaoPresencial = 'PosGraduacaoPresencial',
  MBAEadFull = 'MBAEadFull',
  MBAEad = 'MBAEad',
  PosGraduacaoEadFullIntegracaoInstitucional = 'PosGraduacaoEadFullIntegracaoInstitucional',
  PosGraduacaoEadIntegracaoInstitucional = 'PosGraduacaoEadIntegracaoInstitucional',
  Graduacao = 'Graduacao',
  Evento = 'Evento',
  Outra = 'Outra'
}

export enum ModalidadeCursoPorExtenso {
  'Livre' = 'Curso Livre',
  'PosGraduacaoEad' = 'Pós Graduação EAD',
  'PosGraduacaoEadFlex' = 'Pós Graduação EAD Flex',
  'PosGraduacaoEadFull' = 'Pós Graduação EAD Full',
  'PosGraduacaoPresencial' = 'Pós Graduação Presencial',
  'MBAEadFull' = 'MBA Ead Full',
  'MBAEad' = 'MBA Ead',
  'PosGraduacaoEadFullIntegracaoInstitucional' = 'Pós Graduação Ead Full | Integração Institucional',
  'PosGraduacaoEadIntegracaoInstitucional' = 'Pós Graduação Ead | Integração Institucional',
  'Graduacao' = 'Graduação/Tecnólogo',
  'Evento' = 'Evento',
  'Outra' = 'Outra'
}

export enum ModalidadeCursoDeclaracaoPorExtenso {
  'Livre' = 'Curso Livre',
  'PosGraduacaoEad' = 'Pós Graduação',
  'PosGraduacaoEadFull' = 'Pós Graduação',
  'PosGraduacaoPresencial' = 'Pós Graduação',
  'MBAEadFull' = 'MBA Ead Full',
  'MBAEad' = 'MBA Ead',
  'PosGraduacaoEadFullIntegracaoInstitucional' = 'Pós Graduação Ead Full | Integração Institucional',
  'PosGraduacaoEadIntegracaoInstitucional' = 'Pós Graduação Ead | Integração Institucional',
  'Graduacao' = 'Graduação/Tecnólogo',
  'Evento' = 'Evento'
}

export interface ModalidadePagamentoCurso {
  formaPagamento: keyof typeof MetodosPagamento
  quantidadeParcelas: number
  valorParcelas: number
}

export interface ModalidadePlanoPagamentoCurso {
  formaPagamento: keyof typeof MetodosPagamento
  quantidade: number
  valorParcela: number
  precoBase: number
  modalidade: ModalidadeCurso
}

export interface Curso {
  id?: string
  sigla: string
  nome: string
  descricao: string
  duracao?: number
  turmaId?: string
  codigoTurma?: string
  dataInicioTurma?: string
  dataFimTurma?: string
  localizacao?: string
  chaveContrato?: string
  valorTaxaMatricula?: number
  quantidadeMaximaParcelas?: number
  modalidadesPagamentoTaxaMatricula?: ModalidadePagamentoCurso[]
  valorCurso?: number
  precoBase: number
  modalidadesPagamentoCurso?: ModalidadePlanoPagamentoCurso[]
  modalidade: keyof typeof ModalidadeCurso
  cargaHoraria: number
  situacao: keyof typeof CursoSituacao
  informacoes?: string
  informacoesIniciais?: string
  contrato?: Contrato
  versoCertificado?: string
  tipoAvaliacao?: TipoAvaliacao
  tcc: keyof typeof TCCSituacao
  planoPagamento: PlanoPagamentoParcelas[]
  taxaTrocaDeCurso: number
  prorrogacoes: PlanoPagamentoProrrogacao[]
  periodoMatriculaValido?: boolean
}

export interface CursosCarrinho {
  cursos: Curso[]
  carrinhoCompraId?: string
  matriculas?: Matricula[]
  boletoGerado: boolean
}

export interface PlanoPagamento {
  id: string
  quantidadeParcelas: number
  valor: number
  texto: string
}

export interface PlanoPagamentoParcelas {
  id?: string
  cursoId?: string
  precoBase?: number
  valorParcela: number
  quantidade: number
  formaPagamento: string
}

export interface PlanoPagamentoParcela {
  id?: string
  cursoId?: string
  precoBase?: number
  valorParcela: number
  quantidade: number
  formaPagamento: string
}

export enum CursoSituacao {
  Ativo,
  Inativo,
  Todos
}

export interface DadosPesquisaCurso {
  sigla: string
  nome: string
  modalidade: ModalidadeCurso
  situacao: keyof typeof CursoSituacao
}

export enum TipoAvaliacao {
  AvaliacaoPorModulo = 0,
  AvaliacaoPorDisciplina = 1
}

export enum TipoRecuperacao {
  Modulo = 'Modulo',
  Disciplina = 'Disciplina'
}

export interface Contrato {
  titulo: string
  id: string
  arquivo: File | string
}

export enum TCCSituacao {
  Sim = 'Sim',
  Nao = 'Nao'
}

export enum GradeCurricularSituacao {
  Inativo = 0,
  Ativo = 1
}

export interface GradeCurricularDisciplina {
  id: string
  nome: string
  cargaHoraria: number
}

export interface GradeCurricularModulo {
  nome: string
  informacoesAdicionais?: string
  tipoModulo?: string
  ordem: number
  disciplinas: GradeCurricularDisciplina[]
}

export interface GradeCurricular {
  nome: string
  situacao: keyof typeof GradeCurricularSituacao
  tipoAvaliacao: keyof typeof TipoAvaliacao
  modulos: GradeCurricularModulo[]
  moduloTCC: GradeCurricularModuloTCC
}

export interface GradeCurricularModuloTCC {
  nome: string
  disciplinas: GradeCurricularDisciplina[]
}

export interface CarrinhoCompraExistenteUsuario {
  carrinhoCompraCursoLivreId: string
  dataCriacao: Date
  statusCarrinho: StatusCarrinhoCompra
  aguardandoPagamento: boolean
  cursosCarrinhoCompra: Curso[]
}

export interface VerificarCarrinhoCompraExistenteUsuario {
  possuiCarrinhoAberto: boolean
  carrinhosCompraAbertos: CarrinhoCompraExistenteUsuario[]
}

export enum ModalidadeLivre {
  CursoRapido = 'CursoRapido',
  Formacao = 'Formacao',
  Evento = 'Evento',
  AoVivo = 'AoVivo'
}

export enum TipoAvaliacaoPorExtenso {
  AvaliacaoPorModulo = 'AvaliacaoPorModulo',
  AvaliacaoPorDisciplina = 'AvaliacaoPorDisciplina'
}

export interface PlanoPagamentoProrrogacao {
  id?: string
  quantidadeMeses: string
  valor: string
  quantidadeParcelas: string
}

export interface PlanoPagamentoReingresso {
  id?: string
  quantidade: number
  formaPagamento: string
}

export const validaEhModalidadeLivreOuEvento = (
  modalidade: ModalidadeCurso | keyof typeof ModalidadeCurso
): boolean =>
  modalidade === ModalidadeCurso.Livre || modalidade === ModalidadeCurso.Evento

export const validaEhModalidadeLivre = (
  modalidade: ModalidadeCurso | keyof typeof ModalidadeCurso
): boolean => modalidade === ModalidadeCurso.Livre

export enum FormaPagamentoPorExtenso {
  CartaoCredito = 'Cartão de Crédito',
  Boleto = 'Boleto',
  CupomDesconto = 'Cupom de desconto',
  Pix = 'Pix',
  PagSeguro = 'PagSeguro',
  TED = 'TED',
  Dinheiro = 'Dinheiro',
  Deposito = 'Depósito',
  PayPal = 'PayPal',
  Cheque = 'Cheque'
}
